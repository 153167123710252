'use client'

import { PriceElement } from './PriceElement'
import type { PriceProps } from './types'
import { useSelectedAddonsTariffNos } from '../PriceBreakdown/hooks/useSelectedAddonsTariffNos'

export const Price: React.FC<PriceProps> = ({ contractTemplate, ...props }) => {
  const { selectedAddonsTariffNos } = useSelectedAddonsTariffNos({ contractTemplate })

  return (
    <PriceElement
      contractTemplate={contractTemplate}
      addonsTariffNos={selectedAddonsTariffNos}
      {...props}
    />
  )
}
