import { browserEnvs } from '../envs'

export const formatPostalCodeValue = (value?: string): string | undefined => {
  switch (browserEnvs.NEXT_PUBLIC_COUNTRY) {
    case 'GB': //Storybook
    case 'SE':
      return value?.replace(/(\d{3})(\d{1,2})/, `$1 $2`)
    case 'FI':
    case 'NO':
    default:
      return value
  }
}
