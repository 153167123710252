import styled from 'styled-components'

import { Checkbox, InputField, spacing } from '@fortum/elemental-ui'

export const StyledInput = styled(InputField)`
  input,
  :enabled:focus {
    border-radius: ${spacing.xxxxs};
  }

  label {
    color: ${({ theme }) => theme.colors.textDisabled};
  }
`

export const StyledCheckbox = styled(Checkbox)`
  label {
    color: ${({ theme }) => theme.colors.textSecondary};
  }
`
