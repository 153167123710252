import { useTranslations } from 'next-intl'

import type { FontSize } from '@fortum/elemental-ui'
import { ContentText, fontWeights, spacing } from '@fortum/elemental-ui'

import { getPriceBaseOnCustomerType } from '@/open-web/utils/getPriceBaseOnCustomerType'
import type { TariffElement } from '@/shared/graphql/schema/commonBackend/graphql'
import { useTheme } from '@/shared/hooks/useTheme'
import type { EnrichedContractTemplate } from '@/shared/services/campaignDataResolver'

import { defineTheme, formatFinnishPrice } from '../utils'

export type EnergyPriceProps = {
  tariffElement: TariffElement
  contractTemplate: EnrichedContractTemplate
  isHourlyPriced?: boolean
  priceSize?: FontSize
  theme?: 'light' | 'dark'
}

export const EnergyPrice = ({
  tariffElement,
  isHourlyPriced = false,
  contractTemplate,
  theme = 'light',
  priceSize = 'xl',
}: EnergyPriceProps) => {
  const tPurchaseFlow = useTranslations('purchaseFlow')
  const { colors } = useTheme()
  const colorConfiguration = defineTheme(colors, theme)

  const energyPrice = getPriceBaseOnCustomerType(
    tariffElement.pricesByArea?.FI?.[0],
    contractTemplate.customerType,
  )

  const formattedEnergyPrice = formatFinnishPrice(energyPrice, tariffElement.priceUnit ?? undefined)

  return (
    <>
      <ContentText size="s" color={colorConfiguration.crossedPrice} mt={spacing.xxxs}>
        {tPurchaseFlow('electricityPrice')}
      </ContentText>
      <ContentText
        size={priceSize}
        fontWeight={fontWeights.medium}
        color={colorConfiguration.price}
        data-testid="contract-price"
      >
        {`${isHourlyPriced ? `${tPurchaseFlow('hourlyPrice')} + ` : ''}${formattedEnergyPrice}`}
      </ContentText>
    </>
  )
}
