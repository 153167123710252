'use client'

import type { ChangeEvent } from 'react'

import { InputField, type InputFieldProps } from '@fortum/elemental-ui'

import { StyledInput as CheckoutStyledInput } from '@/open-web/components/Checkout/styled'
import { StyledInput as QuickSearchStyledInput } from '@/open-web/components/QuickSearch/styled'
import { formatPostalCodeValue } from '@/shared/utils/postalCodeFormat'
import { getPostalCodeRegex } from '@/shared/utils/validators/regexConstants'

type InputPostalCodeStyleOptions = 'checkout' | 'elemental' | 'quickSearch' | 'spotPrice'

export type InputPostalCodeProps = InputFieldProps & { inputStyle: InputPostalCodeStyleOptions }

export const InputPostalCode = ({
  inputStyle,
  inputRef,
  value,
  onChange,
  ...props
}: InputPostalCodeProps) => {
  const StyledInput = getInputStyle(inputStyle)

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (getPostalCodeRegex().test(event.target.value)) {
      event.target.value = event.target.value.replaceAll(/\D/g, '')

      onChange && onChange(event)
    }
  }

  return (
    <StyledInput
      {...props}
      value={formatPostalCodeValue(value)}
      onChange={handleChange}
      inputProps={{
        maxLength: 6,
        inputMode: 'numeric',
      }}
    />
  )
}

const getInputStyle = (inputStyle: InputPostalCodeStyleOptions) => {
  switch (inputStyle) {
    case 'checkout':
      return CheckoutStyledInput
    case 'quickSearch':
    case 'spotPrice':
      return QuickSearchStyledInput
    case 'elemental':
      return InputField
  }
}
